@import 'Styles/includes';

.Hero {
    $root: &;

    position: relative;

    // If dark theme or has entrances on front page
    &--Dark,
    &--NoBg#{$root}--HasEntrances {
        color: white;
        background: $red;
    }

    // If light theme
    &--Light {
        color: $grey-90;
        background: $red-5;
    }

    // Set different fallback if entrances and bg is missing on front page
    &--NoBg:not(#{$root}--HasEntrances) {
        margin-bottom: 25px;
        color: $grey-90;
        background: white;

        @include media(M) {
            margin-bottom: 64px;
        }
    }

    // Override with different background color if image or video exists
    &--HasBg {
        background: transparent !important;
    }

    &__Background {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        overflow: hidden;

        @include media(M) {
            max-height: none;
        }

        img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center center;
        }

        &::after {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;

            #{$root}--Dark#{$root}--IsCenter & {
                background: black;
                opacity: .7;
            }

            #{$root}--Light#{$root}--IsCenter & {
                background: white;
                opacity: .6;
            }

            #{$root}--Dark:not(#{$root}--IsCenter) & {
                background-image: linear-gradient(to top, black, rgba(black, 0)),
                    linear-gradient(47deg, black -20%, rgba(black, 0) 77%);
                opacity: .4;
            }

            #{$root}--Light:not(#{$root}--IsCenter) & {
                background-image: linear-gradient(to top, white, rgba(white, 0)),
                    linear-gradient(47deg, white -20%, rgba(white, 0) 77%);
                opacity: .4;
            }
        }
    }

    &__Video {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        padding: 0;
        overflow: hidden;
    }

    &__Wrap {
        @include wrap;

        display: flex;
        flex-direction: column;
        position: relative;
        min-height: 432px;

        #{$root}--NoBg & {
            min-height: 0;
        }

        #{$root}--Large & {
            min-height: 30vw;
        }

        #{$root}--Large#{$root}--NoBg {
            min-height: 24vw;
        }
    }

    &__Container {
        flex: 1 0 auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        position: relative;

        #{$root}--Center & {
            align-items: center;
        }

        #{$root}--HasEntrances & {
            padding-bottom: 82px;

            @include media(ML) {
                padding-bottom: 90px;
            }
        }

        #{$root}--HasVideo#{$root}--NoEntrances & {
            padding-bottom: 50px;
        }
    }

    &__Content {
        flex: 0 0 auto;
        max-width: map-get($maxwidths, content);
        padding: 60px 0 25px 0;

        @include media(M) {
            padding: 64px 0;
        }

        #{$root}--Center & {
            text-align: center;
        }

        #{$root}--NoBg & {
            padding-bottom: 0 !important;
        }
    }

    &__Title {
        @include textstyle(h1);
    }

    &__Text {
        @include textstyle(body-regular);

        margin-top: 12px;

        @include media(M) {
            margin-top: 16px;
        }
    }

    &__Buttons {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        margin-top: 28px;

        @include media(SL) {
            margin-top: 36px;

            #{$root}--Center & {
                justify-content: center;
            }
        }
    }

    &__Button {
        #{$root} & {
            margin-top: 16px;
            margin-right: 16px;

            &:last-child {
                margin-right: 0;
            }

            @include media(SL) {
                margin-top: 0;
            }
        }

        cursor: pointer;

        &--Play {
            svg {
                display: inline-block;
                position: relative;
                top: 2px;
                width: 14px;
                height: 18px;
                margin: -2px 8px -2px 0;
            }
        }
    }

    &__VideoButton {
        position: absolute;
        bottom: 21px;
        right: 0;
        padding: 9px 12px 9px 44px;
        color: $grey-30;
        font-size: 1.3rem;
        font-weight: normal;
        border: none;
        border-radius: 16px;
        box-shadow: none;
        background: transparent;
        -webkit-appearance: none;
        cursor: pointer;
        transition: background $transition, color $transition;

        #{$root} & {
            margin: 0 0 0 -12px;
        }

        @include media(ML) {
            bottom: 25px;
            right: -12px;
        }

        #{$root}--HasEntrances & {
            bottom: 52px;

            @include media(ML) {
                bottom: 64px;
            }
        }

        #{$root}--Light & {
            color: $grey-90;
        }

        &::before {
            content: '';
            display: block;
            position: absolute;
            top: 6px;
            left: 12px;
            width: 21px;
            height: 21px;
            border: 2px solid $grey-30;
            border-radius: 50%;
            transition: border-color $transition;

            #{$root}--Light & {
                border-color: $grey-90;
            }
        }

        &::after {
            content: '';
            display: block;
            position: absolute;
            top: 12px;
            left: 18px;
            width: 9px;
            height: 9px;
            background: transparent no-repeat center center;
            background-size: contain;
            transition: background-image $transition;
        }

        &:hover {
            color: $grey-90;
            background: $grey-30;

            &::before {
                border-color: $grey-90;
            }

            #{$root}--Light & {
                color: $grey-30;
                background: $grey-90;

                &::before {
                    border-color: $grey-30;
                }
            }
        }

        &--Pause {
            &::after {
                background-image: url(#{$assetsPath}/img/pause--grey-30.svg);
            }

            &:hover::after {
                background-image: url(#{$assetsPath}/img/pause--grey-90.svg);
            }
        }

        &--Play {
            &::after {
                left: 19px;
                background-image: url(#{$assetsPath}/img/play--grey-30.svg);
            }

            &:hover::after {
                background-image: url(#{$assetsPath}/img/play--grey-90.svg);
            }
        }

        #{$root}--Light & {
            &--Pause {
                &::after {
                    background-image: url(#{$assetsPath}/img/pause--grey-90.svg);
                }

                &:hover::after {
                    background-image: url(#{$assetsPath}/img/pause--grey-30.svg);
                }
            }

            &--Play {
                &::after {
                    left: 7px;
                    background-image: url(#{$assetsPath}/img/play--grey-90.svg);
                }

                &:hover::after {
                    background-image: url(#{$assetsPath}/img/play--grey-30.svg);
                }
            }
        }
    }

    &__Modal {
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        background: rgba(black, .6);
        z-index: 200;
    }

    &__ModalContainer {
        position: absolute;
        top: 50%;
        left: 50%;
        max-width: calc(100vw - 16px);
        width: 100%;
        transform: translate(-50%, -50%);

        @include media(SL) {
            width: 80%;
            max-width: 1000px;
        }
    }

    &__ModalContent {
        width: 100%;
        height: 0;
        padding-bottom: #{percentage(9/16)};

        iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }

    &__ModalVideo {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
    }

    &__ModalClose {
        position: absolute;
        top: -40px;
        right: -8px;
        width: 32px;
        height: 32px;
        margin: 0;
        border: none;
        border-radius: 0;
        box-shadow: none;
        background: transparent;
        -webkit-appearance: none;
        cursor: pointer;

        @include media(M) {
            top: -8px;
            right: -64px;
        }

        &::before,
        &::after {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            width: 16px;
            height: 3px;
            margin: auto;
            background: white;
            transform: rotate(45deg);
        }

        &::after {
            transform: rotate(-45deg);
        }
    }
}
